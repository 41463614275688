import React, { useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

const Card = styled(animated.div)`
  position: absolute;
  bottom: 0;
  right: 0;

  @media (min-width: 800px) {
    bottom: ${({movedFromSide}) => movedFromSide ? '50px' : '60px'};
    right: ${({movedFromSide}) => movedFromSide ? '30px' : '10px'};
  }
`;

const ThreeDCard = ({ children, move, movedFromSide }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const cardEl = useRef();

  const calc = (x, y) => [
    -(y - cardEl.current.getBoundingClientRect().top - cardEl.current.offsetHeight / 2) / 20,
    (x - cardEl.current.getBoundingClientRect().left - cardEl.current.offsetWidth / 2) / 30,
    1.1
  ];
  const trans = (x, y) => `perspective(2000px) rotateX(${x}deg) rotateY(${y}deg)`;

  const [springProps, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: {
      mass: 5,
      tension: 350,
      friction: 40
    }
  }));

  const setFromEvent = ({ clientX: x, clientY: y }) => set({ xys: calc(x, y) });

  useEffect(() => {
    !isTabletOrMobile && window.addEventListener('mousemove', setFromEvent);

    return () => {
      !isTabletOrMobile && window.removeEventListener('mousemove', setFromEvent);
    };
  }, [isTabletOrMobile]);

  return (
    <Card
      movedFromSide={movedFromSide}
      ref={cardEl}
      style={{
        transform: springProps.xys.interpolate(trans)
      }}
    >
      {children}
    </Card>
  );
};

export default ThreeDCard;
