import React from 'react';
import '../styles/fonts.css';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { GlobalStyle } from './globalStyle';
import { MainWrapper } from './layoutComponents';
import Footer from './footer';

const Layout = ({ saver, children, showCard, showCardMobile, showName, data, transitionStatus }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            image
          }
        }
        sako {
          generals {
            cardImage {
              handle
              url
            }
            cardVideo {
              url
            }
            eMail
            instagram
            vimeo
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <GlobalStyle />
          <MainWrapper>
            <main>{children}</main>
            <Footer
              showCard={showCard}
              showCardMobile={showCardMobile}
              showName={showName}
              data={data.sako}
              transitionStatus={transitionStatus}
              saver={saver}
            />
          </MainWrapper>
        </>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showCard: PropTypes.bool,
  showCardMobile: PropTypes.bool
};

Layout.defaultProps = {
  showCard: false,
  showCardMobile: true
};

export default Layout;
