import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { Container } from './layoutComponents';
import ThreeDCard from './ThreeDCard';
import { TransitionState } from 'gatsby-plugin-transition-link';
import Title from './shared/Title';

const Wrapper = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 17px 15px #fff;
  background-color: ${({saver}) => saver ? 'transparent' : '#fff'};

  @media (min-width: 800px) {
    padding: 0 20px;
    height: 50px;
    line-height: 50px;
    box-shadow: ${({saver}) => saver ? 'none' : '0 -5px 17px 15px #fff'};
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
`;

const LinksWrapper = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${({saver}) => saver ? 'transparent' : '#fff'};
  box-shadow: 0 0 17px 15px #fff;
  padding-bottom: 10px;
  
  @media (min-width: 800px) {
    background-color: transparent;
    box-shadow: none;
    padding-bottom: 0;
    color: ${({saver}) => saver ? '#fff' : '#000'};
  }
`;

const FooterLink = styled.a`
  color: inherit;
  font-size: 30px;
  font-size: 7vw;
  margin: 0 5px;
  white-space: nowrap;

  @media (min-width: 800px) {
    font-size: 21px;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  font-size: 21px;
  margin: 0 5px;
  white-space: nowrap;
  position: absolute;
  right: 0;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Video = styled.video`
  width: 50vw;
  height: auto;

  @media (min-width: 800px) {
    height: auto;
    width: 400px;
  }
`;

const Photo = styled.img`
  height: auto;
  width: 50vw;

  @media (min-width: 800px) {
    height: auto;
    width: 400px;
  }
`;

const Footer = ({ saver, showCard, data, showName, showCardMobile, transitionStatus }) => {
  const footerData = data.generals[0];

  const videoEl = useRef(null);

  useEffect(() => {
    if (videoEl && videoEl.current) {
      videoEl.current.muted = true;
      videoEl.current.play();
    }
  }, []);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <Wrapper saver={saver}>
      <Container>
        <ContentContainer>
          <TransitionState>
            {({ mount }) => {
              return !isTabletOrMobile && mount && showName && <Title shadow={false} inFooter={true} as="h3">Paweł Sakowicz</Title>;
            }}
          </TransitionState>
          <LinksWrapper saver={saver}>
            <FooterLink href={`mailto:${footerData.eMail}`} target='_blank'>
              e-mail,
            </FooterLink>
            <FooterLink href={footerData.instagram} target='_blank'>
              instagram,
            </FooterLink>
            <FooterLink href={footerData.vimeo} target='_blank'>
              vimeo
            </FooterLink>
          </LinksWrapper>
          <TransitionState>
            {({ mount }) => {
              return !isTabletOrMobile && mount && showName && <StyledLink to='/'>Back &#x021A9;</StyledLink>;
            }}
          </TransitionState>
        </ContentContainer>
        <TransitionState>
          {({ mount }) => {
            return (
              (mount || transitionStatus === 'POP') &&
              (showCardMobile || !isTabletOrMobile) &&
              showCard && (
                <ThreeDCard mount={mount} movedFromSide={true}>
                  {typeof window !== 'undefined' && 
                    (footerData.cardVideo ? (
                      <Video ref={videoEl} autoPlay loop muted={true} defaultMuted playsInline poster={footerData.cardImage.url}>
                        <source src={footerData.cardVideo.url} type='video/mp4' />
                      </Video>
                    ) : (
                      <Photo src={footerData.cardImage.url} imageUrl={footerData.cardImage.url} />
                    ) 
                  )}   
                </ThreeDCard>
              )
            );
          }}
        </TransitionState>
      </Container>
    </Wrapper>
  );
};

Footer.propTypes = {
  showName: PropTypes.bool,
  showCardMobile: PropTypes.bool
};

Footer.defaultProps = {
  showName: true,
  showCardMobile: true
};

export default Footer;
