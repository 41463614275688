import styled from 'styled-components';

const Title = styled.h1`
  display: inline-block;
  position: absolute;
  top: ${({inFooter}) => inFooter ? '5px' : 0};
  left: 15px;
  z-index: 1;
  font-size: 21px;
  line-height: 1;
  font-weight: initial;
  background-color: #fff;
  padding: 10px 10px 10px 0;
  box-shadow: ${({shadow, saver}) => shadow ? saver ? '-12px -21px 18px 39px #fff' : '0 -5px 17px 15px #fff' : 'none'};
  font-family: kormelink;
  letter-spacing: 0;

  @media (min-width: 800px) {
    left: 0px;
  }
`;

export default Title;
